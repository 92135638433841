//npm run serve pour modif
//npm run build quand modif ok
//click droit sur dossier dist puis deployement puis upload

<template>
  <nav style="background-color: #cab2a3; border-radius: 20px">
    <router-link to="/">Accueil</router-link> |
    <router-link to="/grossesse">Grossesse</router-link> |
    <router-link to="/naissance">Naissance</router-link> |
    <router-link to="/premiereBougie">Première Bougie</router-link> |
    <router-link to="/famille">Famille</router-link> |
    <router-link to="/theme">Theme</router-link> |
    <router-link to="/mentionLegal">Mention Legal</router-link>
  </nav>
  <router-view/>
</template>

<style>
#app {
  background-color: #FDF9ED;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: #e1cbd3;
  text-decoration: none;
}

body {
  margin: 0px;
}
</style>
