import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [

  {
    path: '/famille',
    name: 'famille',
    component: () => import(/* webpackChunkName: "about" */ '../views/famille.vue')
  },
  {
    path: '/',
    name: 'accueil',
    component: () => import(/* webpackChunkName: "about" */ '../views/accueil.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact.vue')
  },
  {
    path: '/grossesse',
    name: 'grossesse',
    component: () => import(/* webpackChunkName: "about" */ '../views/grossesse.vue')
  },
  {
    path: '/mentionLegal',
    name: 'mentionLegal',
    component: () => import(/* webpackChunkName: "about" */ '../views/mentionLegal.vue')
  },
  {
    path: '/naissance',
    name: 'naissance',
    component: () => import(/* webpackChunkName: "about" */ '../views/naissance.vue')
  },
  {
    path: '/premiereBougie',
    name: 'premiereBougie',
    component: () => import(/* webpackChunkName: "about" */ '../views/premiereBougie.vue')
  },
  {
    path: '/theme',
    name: 'theme',
    component: () => import(/* webpackChunkName: "about" */ '../views/theme.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
